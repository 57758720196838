import React, { useMemo } from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { classicRestClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import { useColumnDefinitions } from 'src/components/admin-table/hooks';
import { useMultiSelectPlaceholder } from 'src/components/filter-bar/hooks';
import { FilterOption } from 'src/components/filter-bar/types';
import { defaultFilterSpacing } from 'src/components/organisations/constants';
import { VIEWING_CONTEXT_MAP, ViewingContext } from 'src/components/organisations/organisation-profile/config';
import { useOrganisationSchemes, useViewingContext } from 'src/components/organisations/organisation-profile/hooks';
import PageHeader from 'src/components/page-header/page-header';
import Panel from 'src/components/panel/panel';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { getClientConfig, getRootProviderId } from 'src/config/config';
import { PropertySortInput } from 'src/graphql-types/globalTournamentTypes';
import { Spacing } from 'src/hooks/spacing';
import { initQueryFilterTableHook } from 'src/hooks/use-query-filter-table';
import { useRegions } from 'src/hooks/use-regions';
import { GET_SCHEME_COURSES_BY_TENANT } from 'src/queries/REST/organisations';
import { SchemeCourse } from 'src/types/classic';
import { GetOrganisationSchemeCoursesByTenant } from 'src/types/classic-queries';
import { EQUALITY_OPERATOR, SORT_DIRECTION } from 'src/utils/constants/classic';
import { sortByName } from 'src/utils/helper';
import { generateDateAtMidnight } from 'src/utils/helper/dates';
import { mapToClassicSorts } from 'src/utils/helper/sortOptions';

import { useSchemeCourseStatusFilter } from './hooks';
import { SchemeCoursesListProps } from './types';

const accessoriesBarSpacing: Spacing = {
  base: 5,
  margins: {
    sm: 'top',
    md: 'bottom',
  },
};

type TableItem = SchemeCourse & {
  id: string;
};
const useQFT = initQueryFilterTableHook<GetOrganisationSchemeCoursesByTenant, TableItem>(GET_SCHEME_COURSES_BY_TENANT);

const SchemeCoursesList = ({
  schemeSubCategory,
  tableId,
  transKeys,
  columnsConfig,
  filtersConfig,
}: SchemeCoursesListProps) => {
  const { t } = useTranslation();
  const { hiddenStatusFilterOptions, categoryFilter, regionFilter } = filtersConfig;
  const tenantId = getRootProviderId();
  const selectedFacilityId = useOrgId();
  const { viewingContext } = useViewingContext() ?? {};
  const clientConfig = useMemo(getClientConfig, []);

  const shouldDefaultToNationalRegion = viewingContext === VIEWING_CONTEXT_MAP.REGIONAL;

  const { data: regionsData, loading: loadingRegions } = useRegions(tenantId);

  const { data: schemesData, loading: loadingSchemes } = useOrganisationSchemes(tenantId, {
    subCategory: schemeSubCategory,
  });

  const tableCtx = useMemo(() => {
    const regionMap = {};
    regionsData?.findOrganisations?.results?.forEach?.(({ id, organisationName }) => {
      regionMap[id] = organisationName;
    });
    return { regionData: { kvMap: regionMap, loading: loadingRegions } };
  }, [regionsData, loadingRegions]);

  const columns = useColumnDefinitions(columnsConfig, tableCtx);

  const categoryOptions: FilterOption<string>[] = useMemo(() => {
    const schemes = schemesData?.searchOrganisationSchemes?.Schemes ?? [];
    return sortByName(schemes, { fieldName: 'Name', order: 'A-Z' }).map((c) => ({
      label: c.Name,
      value: `${c.Category}`,
    }));
  }, [schemesData]);

  const initialDateFilter = useMemo<any>(() => {
    const toDate = new Date();
    toDate.setMonth(toDate.getMonth() + 6);
    return {
      from: generateDateAtMidnight(),
      to: generateDateAtMidnight(toDate.toISOString()),
    };
  }, []);

  const getCategoryFilterPlaceholder = useMultiSelectPlaceholder({
    options: categoryOptions,
    transKeyType: categoryFilter.schemeCourseTransKey,
    transKeyNoneSelected: categoryFilter.defaultLabelTransKey,
  });

  const { statusOptions, getStatusFilterPlaceholder, getStatusQueryFilter } = useSchemeCourseStatusFilter({
    hiddenOptions: hiddenStatusFilterOptions,
  });

  const {
    components: { FilterBar, AccessoryBar, AdminTable, props },
    query: { error },
  } = useQFT(
    {
      date: {
        position: 0,
        type: 'date-range-dropdown',
        initialValue: [initialDateFilter.from, initialDateFilter.to],
        props: {
          withChevron: true,
          initFilter: initialDateFilter,
          datePickerFormat: clientConfig.datePickerDateFormat,
        },
        spacing: defaultFilterSpacing,
      },
      approverRegion: {
        position: 1,
        type: 'select:region',
        initialValue: shouldDefaultToNationalRegion ? selectedFacilityId : '',
        props: {
          defaultOptionLabel: t(regionFilter.transKeyDefaultOption),
        },
        hide: regionFilter.visibility && !regionFilter.visibility.includes(viewingContext as ViewingContext),
        spacing: defaultFilterSpacing,
      },
      category: {
        position: 3,
        type: 'multi-select',
        initialValue: [],
        props: {
          placeholder: getCategoryFilterPlaceholder,
          options: categoryOptions,
          withChevron: true,
          disabled: loadingSchemes,
        },
        spacing: defaultFilterSpacing,
      },
      status: {
        position: 2,
        type: 'multi-select',
        initialValue: [],
        props: { placeholder: getStatusFilterPlaceholder, options: statusOptions, withChevron: true },
        spacing: defaultFilterSpacing,
      },
    },
    {
      columns,
      accessoryBar: {
        items: [
          { position: 'right', type: 'column-toggle', props: {} },
          { position: 'left', type: 'pagination-info-top', props: {} },
        ],
        spacing: accessoriesBarSpacing,
      },
      tableProps: {
        tableId,
        hideTopPaginationInfo: true,
        rowsPerPageOptions: [25, 50],
      },
    },
    {
      columnSelectorId: tableId,
      mapFiltersToQueryOptions: ({ filters }) => {
        return {
          client: classicRestClient,
          getTotalItems: (data) => data?.organisationSchemeCoursesByTenant?.TotalItems ?? 0,
          transformVariables: (vars: { limit?: number; offset?: number; sorts?: PropertySortInput[] }) => {
            const { limit, offset, sorts } = vars;
            const { category, status, date, approverRegion } = filters;
            const [dateStart, dateEnd] = date;
            const queryFilters: any[] = [getStatusQueryFilter(status)];
            const defaultSorting = [
              {
                Property: 'StartDate',
                SortDirection: SORT_DIRECTION.ASCENDING,
              },
            ];

            if (category?.length)
              queryFilters.push({
                Property: 'Scheme.Category',
                Values: category,
                Operator: EQUALITY_OPERATOR.IN,
              });

            const QueryParametersSorts = sorts?.find(
              (sort) => sort.property === 'StartDate' || sort.property === 'Scheme.Venue.Name',
            );

            return {
              input: {
                ParentRegionId: tenantId,
                RegionID: approverRegion || undefined,
                StartDate: dateStart,
                EndDate: dateEnd,
                SchemeSubCategory: schemeSubCategory,
                Sorts: sorts && !QueryParametersSorts ? mapToClassicSorts(sorts) : undefined,
                QueryParametersPaged: {
                  Limit: limit,
                  Offset: offset,
                  Filters: queryFilters,
                  Sorts: sorts && QueryParametersSorts ? mapToClassicSorts(sorts) : defaultSorting,
                },
              },
            };
          },
          tableId,
          defaultFetchLimit: 25,
          fetchPolicy: 'no-cache',
        };
      },
      mapDataToTable: (data) => data?.organisationSchemeCoursesByTenant?.Items ?? [],
      depsFilterConfig: [
        t,
        categoryOptions,
        statusOptions,
        getCategoryFilterPlaceholder,
        getStatusFilterPlaceholder,
        loadingSchemes,
        regionFilter,
      ],
      depsTableConfig: [t, columns, regionsData, loadingRegions],
    },
  );

  return (
    <PageMaxWidth>
      <Grid container justifyContent="space-between" alignItems="center">
        <PageHeader title={t(transKeys.title)} />
      </Grid>
      <Panel spacing={{ margins: { md: 'top' } }}>
        <FilterBar {...props.filterBar} withClearBtn />
        <AccessoryBar {...props.accessoryBar} />
        <AdminTable {...props.adminTable} error={error ? t(transKeys.queryError) : undefined} />
      </Panel>
    </PageMaxWidth>
  );
};

export default SchemeCoursesList;
