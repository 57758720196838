import { orgModuleFeatureMap } from './constants';

export interface OrganisationProfileTab {
  orgId: string;
  orgName?: string;
  transKeyTitle?: string;
  transKeyQueryError?: string;
}

export type OrganisationModuleFeature = (typeof orgModuleFeatureMap)[keyof typeof orgModuleFeatureMap];

export enum OrgProfileReferrer {
  MANAGE_ORGANISATIONS = 'manage_organisations',
  CHECKLISTS = 'checklists',
  PROGRAMMES = 'programmes',
  EVENTS = 'events',
}
